
import React, { useRef, useState, useEffect } from 'react';
import './ScreenPDFOutputStreamConnect.css';
import { AudioInput, AudioOutput, Config, SOW, VideoInput, VideoOutput } from '../../../Model/SOW';
import NavigationHeader from '../../../Components/NavigationHeader/NavigationHeader';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../Redux/ActionCreators';
import { Screens } from '../../../Redux/Reducers';
import PDFOutput, { PDFOutputResults } from '../../../Components/PDFOutput/PDFOutput';
import ScreenPDFViewer from '../../ScreenPDFViewer/ScreenPDFViewer';
import ScreenSignaturePage from '../../ScreenSignaturePage/ScreenSignaturePage';
import ScreenCoverPage from '../../ScreenCoverPage/ScreenCoverPage';
import StreamConnectTableOfContents from '../StreamConnectTableOfContents/StreamConnectTableOfContents';
import StreamConnectExecutiveSummary from '../StreamConnectExecutiveSummary/StreamConnectExecutiveSummary';
import StreamConnectScopeOfWork from '../StreamConnectScopeOfWork/StreamConnectScopeOfWork';
import StreamConnectProjectRoadmap from '../StreamConnectProjectRoadmap/StreamConnectProjectRoadmap';
import StreamConnectEnterpriseStrategyMap from '../StreamConnectEnterpriseStrategyMap/StreamConnectEnterpriseStrategyMap';
import StreamConnectTopologyDiagram from '../StreamConnectTopologyDiagram/StreamConnectTopologyDiagram';
import StreamConnectSOWRows from '../../../Utils/StreamConnectSOWRows';
interface ScreenPDFOutputStreamConnectProps {
	currentSOW: SOW;
	onPDFComplete: (pdfData: PDFOutputResults) => void;
}
export default function ScreenPDFOutputStreamConnect({ currentSOW, onPDFComplete }: ScreenPDFOutputStreamConnectProps) {
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		setSOW: ActionCreators.setSOW,
		setPrintingPDF: ActionCreators.setPrintingPDF,
	}, dispatch);



	const objectType = (obj: VideoInput | VideoOutput | AudioInput | AudioOutput) => {
		if ((obj as VideoOutput).outputsButtonText != undefined) {
			return "Video Output";
		}
		if ((obj as VideoOutput).icon != undefined) {
			return "Video Input";
		}
		if ((obj as AudioInput).inputsTPButtonText != undefined) {
			return "Audio Input";
		}
		if ((obj as AudioOutput).outputsTPButtonText != undefined) {
			return "Audio Output";
		}
		return "";
	}

	const purchaseScopeOfWorkRows = StreamConnectSOWRows(currentSOW,false);
	const upgradeScopeOfWorkRows = StreamConnectSOWRows(currentSOW,true);

	const [pdfReadyToGenerate, setPDFReadyToGenerate] = useState(false);

	const listOfReadyDepts:number[] = [];



	return (
		<>
			<div className='Screen ScreenPDFOutputStreamConnect'>
				<PDFOutput
					readyToGenerate={pdfReadyToGenerate}
					onGenerationComplete={(pdfData) => {
						onPDFComplete(pdfData);
						// AC.setPrintingPDF(false);

						// //save pdf as file
						// const a = document.createElement('a');
						// a.href = URL.createObjectURL(pdfData);
						// a.download = 'sow.pdf';
						// a.click();

					}}>
					<ScreenCoverPage
						hospitalName={currentSOW.hospitalName}
						leftText='StreamConnect Statement of Work'
						title1="StreamConnect"
						title2='Statement of Work'
						quoteVersion={currentSOW.quoteVersion ?? ""}
						quoteNumber={currentSOW.externalQuoteNumber ?? ""}
					/>
					<StreamConnectTableOfContents sow={currentSOW} forPDF={true} />
					<StreamConnectExecutiveSummary sow={currentSOW} forPDF={true} currentDepartmentIndex={0} />
					{purchaseScopeOfWorkRows.length > 0 && <>
						<StreamConnectScopeOfWork sow={currentSOW} forPDF={true} currentSowRowIndex={0} isScreenUpgrade={false} />
						{purchaseScopeOfWorkRows.length > 5 && (
							<>
								{(() => {
									let pages: JSX.Element[] = [];
									for (let i = 5; i < purchaseScopeOfWorkRows.length; i += 10) {
										pages.push(<StreamConnectScopeOfWork sow={currentSOW} forPDF={true} currentSowRowIndex={i} isScreenUpgrade={false} />);
									}
									return pages;
								})()}
							</>
						)}
					</>}
					{upgradeScopeOfWorkRows.length > 0 && <>	
						<StreamConnectScopeOfWork sow={currentSOW} forPDF={true} currentSowRowIndex={0} isScreenUpgrade={true} />
						{upgradeScopeOfWorkRows.length > 5 && (
							<>
								{(() => {
									let pages: JSX.Element[] = [];
									for (let i = 5; i < upgradeScopeOfWorkRows.length; i += 10) {
										pages.push(<StreamConnectScopeOfWork sow={currentSOW} forPDF={true} currentSowRowIndex={i} isScreenUpgrade={true} />);
									}
									return pages;
								})()}
							</>
						)}
					</>}
					<StreamConnectProjectRoadmap sow={currentSOW} forPDF={true} currentDepartmentIndex={0} />

					<>
						{(() => {
							let pages: JSX.Element[] = [];
							for (let i = 0; i < currentSOW.departments.length; i += 4) {
								pages.push(<StreamConnectEnterpriseStrategyMap sow={currentSOW} forPDF={true} currentDepartmentIndex={i} />);
							}
							return pages;
						})()}
					</>
					{currentSOW.departments.length%4 == 0 && <>
						<StreamConnectEnterpriseStrategyMap sow={currentSOW} forPDF={true} currentDepartmentIndex={currentSOW.departments.length+1} />
					</>}
					<>
						{(() => {
							let pages: JSX.Element[] = [];
							for (let i = 0; i < currentSOW.departments.length; i += 2) {
								pages.push(<StreamConnectTopologyDiagram sow={currentSOW} forPDF={true} currentDepartmentIndex={i} onReadyForPDF={() =>{
									if(!listOfReadyDepts.includes(i)){
										listOfReadyDepts.push(i);
									}
									if(listOfReadyDepts.length * 2 >= currentSOW.departments.length){
										setPDFReadyToGenerate(true);
									}
									console.log("ready for pdf: "+i);
								}} />);
							}
							return pages;
						})()}
					</>


					<ScreenSignaturePage />
				</PDFOutput>
			</div>
		</>
	);
}