import React, { useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import "./ScreenPDFViewer.css"
import { pdfjs } from 'react-pdf';
import { PDFOutputResults } from '../../Components/PDFOutput/PDFOutput';
import { sendEmail } from '../../Utils/EmailSaveAndLoad';
import { SOW } from '../../Model/SOW';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faSalesforce } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



interface ScreenPDFViewerProps {
  sow: SOW;
  pdfData: PDFOutputResults;
  onDismiss: () => void;
}


const ScreenPDFViewer: React.FC<ScreenPDFViewerProps> = ({ pdfData, sow, onDismiss }) => {
  const [numPages, setNumPages] = React.useState<number | null>(null);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const onSalesforceClick = () => {
    setShowingSalesforceOverlay(true);



    // DEVELOPMENT ENDOINT CLIENT AND SECRET
    const salesforceURLDev = `https://preprodapi.karlstorz.com:443/exp-ks-one-journey-dev/api/statementOfWorks/${sow.id}/documents`
    const clientIdDev = "05539e0553a74c46a9b2314f9682e542"
    const clientSecretDev = "720060a4C8124A2b8ef49835140CB9cF"

    // UAT ENDPOINT CLIENT AND SECRET
    const salesforceURLUat = `https://preprodapi.karlstorz.com:443/exp-ks-one-journey-uat/api/statementOfWorks/${sow.id}/documents`
    const clientIdUat = "99550f3e6660418daf37adbebb5a1ab7"
    const clientSecretUat = "E7D612f696aa491Ca5D72cA0BEE2a19b"

    // PROD ENDPOINT CLIENT AND SECRET
    const salesforceURLProd = `https://api.karlstorz.com/exp-ks-one-journey/api/statementOfWorks/${sow.id}/documents`
    const clientIdProd = "3cf3fc79c3bb45238b76c737e9dc7b23"
    const clientSecretProd = "9FD721A411eB446a9A228e1190c671CC"

    const salesforceURL = salesforceURLProd
    const clientId = clientIdProd
    const clientSecret = clientSecretProd

    let body = {
      "statementOfWorkContent": pdfData.base64Data,
      "quoteVersion": sow.quoteVersion,
      "sfQuoteId": sow.externalQuoteId,
      "quoteNumber": sow.externalQuoteNumber,
      "statementOfWorkId": sow.id
    }

    console.log(salesforceURL);
    console.log(JSON.stringify(body));

    fetch(salesforceURL, {
      method: "POST",
      headers: {
        "client_id": clientId,
        "client_secret": clientSecret,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    }).then(response => response.json()).then(data => {
      if(data.code && data.code !== 200){
        let errorMessage = (data.message || "Error uploading to Salesforce") + "\n";
        errorMessage += (data.description || "");
        alert(errorMessage);
      }else{
        alert("Uploaded to Salesforce");
      }
      console.log(data);
      
    }).catch(error => {
      console.error(error);
      alert("Error uploading to Salesforce");
    }).finally(() => {
      setShowingSalesforceOverlay(false);
    });
  }

  const [showingSalesforceOverlay, setShowingSalesforceOverlay] = React.useState(false);

  // const url = URL.createObjectURL(pdfData);
  return (
    <div className="Screen ScreenPDFViewer">
      <div className='header'>
        <div className='buttonBack' onClick={e => { e.stopPropagation(); onDismiss() }}>Back</div>
        {(sow.externalQuoteId && sow.externalQuoteNumber && sow.quoteVersion) && <>
          <div className='buttonSalesforce' onClick={onSalesforceClick}><FontAwesomeIcon icon={faSalesforce as IconProp} /> Send to Salesforce</div>
        </>}
        <div className='buttonEmail' onClick={() => {

          sendEmail(pdfData.pdfData, sow.name);

          setTimeout(() => {
            onDismiss();
          }, 1000);

        }}><FontAwesomeIcon icon={faDownload} /> Download</div>
      </div>
      <div className='pdfArea'>
        <div className='content'>
          {pdfData.imageDataURLs.map((imageDataURL, index) => {
            return <div className='pdfPage' key={index} style={{ backgroundImage: `url(${imageDataURL})` }} />
          })}
          {/* <Document
            file={url}
            
            onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages || 0), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document> */}
        </div>
      </div>

      {showingSalesforceOverlay && <div className='salesforceOverlay' onClick={e => { e.stopPropagation();}}>
        <h1>Uploading to Salesforce...</h1>
        <h3>This may take a few moments..</h3>
        <div className='salesforceOverlaySpinner' />
      </div>}
    </div>
  );
};

export default ScreenPDFViewer;
