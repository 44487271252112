import React, { useRef, useState, useEffect, useCallback } from 'react';
import './ScreenLanding.css';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../Redux/ActionCreators';
import { Screens } from '../../Redux/Reducers';
import { sharedFirebaseAuth, sharedFirestore } from '../../Utils/SharedFirebase';
import { KarlStorzUser } from '../../Model/KarlStorzUser';
import { deleteDoc, getDocs } from 'firebase/firestore';
import { collection } from 'firebase/firestore';
import { SOW } from '../../Model/SOW';
import { SOWS_TABLE_NAME, SOWS_TABLE_NAME_PROD } from '../../App';
interface ScreenLandingProps {
}
export default function ScreenLanding({ }: ScreenLandingProps) {
	const loggedInUser = useSelector((state: { loggedInUser: KarlStorzUser }) => state.loggedInUser)
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		setSOW: ActionCreators.setSOW,
	}, dispatch);

	const listAllSOWs = useCallback(async () => {
		// const sowsCollectionRef = collection(sharedFirestore, SOWS_TABLE_NAME);
		// const snapshot = await getDocs(sowsCollectionRef);
		// snapshot.forEach((doc) => {
		// 	let docData:SOW = doc.data() as SOW;
		// 	console.log(doc.id, docData.name, docData.deleted ? "deleted" : "not deleted");
		// });
	}, []);

	return (
		<div className='Screen ScreenLanding'>
			<div className='overlay'></div>
			<div className='storzLogo'></div>
			<div className='buttonLogout' onClick={() => {
				sharedFirebaseAuth.signOut();

			}}>Log out</div>
			<div className="menu">
				<div className='header' onClick={listAllSOWs}>Create New SOW</div>
				<div className='row'>
					<div className='buttonCreateSOW'
						onClick={() => {
							AC.setScreen(Screens.ORBegin);
						}}>
						Operating Room Integration
					</div>
					<div className='buttonCreateSOW streamConnect'
						onClick={() => {
							AC.setScreen(Screens.StreamConnectBegin);
						}}>
						StreamConnect<sup>®</sup>
						<div className='smaller'>Enterprise Collaboration Software</div>
					</div>
				</div>
				<div className='buttonLoad' onClick={() => {
					AC.setScreen(Screens.LoadSOW);
				}}>
					Open Existing SOW
				</div>
				{loggedInUser.isAdmin && <>
					<div className='buttonLoad'
						onClick={() => {
							AC.setScreen(Screens.Admin);
						}}>
						Administrator Portal</div>
				</>}

			</div>
			<div className='environment'>
				{(SOWS_TABLE_NAME == SOWS_TABLE_NAME_PROD) ? "Production" : "Development"}
			</div>
		</div>
	);
}