// authConfig.ts or similar
import { PublicClientApplication, Configuration } from "@azure/msal-browser";

const infuseClientId = "ea726432-8ebd-40c3-b4c3-19812859f05d";
const infuseTenantId = "9d9484e0-8106-4895-9999-498c709f9523";

const karlStorzClientId = "e13b9b15-988e-43bf-8aec-f29f86b8e4b8";
const karlStorzTenantId = "4a0b6f21-e6e1-4ed6-9dcf-e60e7190f648";

const clientID = karlStorzClientId;
const tenantID = karlStorzTenantId;

const msalConfig: Configuration = {
  auth: {
    clientId: clientID, 
    authority: "https://login.microsoftonline.com/" + tenantID, 
    redirectUri: window.location.origin  // redirect back to your app after login
    // optionally, postLogoutRedirectUri: window.location.origin
  }
  // ... you can also configure cache location, logger, etc. if needed
};

export const msalInstance = new PublicClientApplication(msalConfig);
