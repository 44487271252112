
import './ScreenCoverPage.css';
interface ScreenCoverPageProps {
	quoteVersion: string;
	quoteNumber: string;
	hospitalName: string;
	leftText: string;
	title1: string;
	title2: string;
}
export default function ScreenCoverPage({ hospitalName, leftText, title1, title2, quoteVersion, quoteNumber }: ScreenCoverPageProps) {

	return (
		<div className='Screen ScreenCoverPage'>
			<div className='verticalLabel pos2'><div className='smaller'>{hospitalName}</div>{leftText}</div>
			<div className='storzLogo'></div>
			<div className='title1'>{title1}</div>
			<div className='title2'>{title2}</div>
			{quoteNumber != "" && <div className='quoteNumber'>Quote Number: {quoteNumber}</div>}
			{quoteVersion != "" && <div className='quoteVersion'>Quote Version: {quoteVersion}</div>}
		</div>
	);
}