
import React, { useRef, useState, useEffect } from 'react';
import './ExecutiveSummaryRow.css';
import { Config } from '../../../Model/SOW';
interface ExecutiveSummaryRowProps {
  config: Config;
}
export default function ExecutiveSummaryRow({ config }: ExecutiveSummaryRowProps) {
  const [system, setSystem] = useState<string>(config.video.system);
  const [inputs, setInputs] = useState<number>(0);
  const [outputs, setOutputs] = useState<number>(0);
  const [surgicalQty, setSurgicalQty] = useState<number>(0);
  const [surgicalSize, setSurgicalSize] = useState<string>("N/A");
  const [wallQty, setWallQty] = useState<number>(0);
  const [wallSize, setWallSize] = useState<string>("N/A");
  const [multiview, setMultiview] = useState<string>("N/A");
  const [capture, setCapture] = useState<string>("N/A");
  const [streaming, setStreaming] = useState<string>("N/A");
  const [conferencing, setConferencing] = useState<string>("N/A");
  const [toms, setToms] = useState<string>("N/A");
  const [checklistDashboard, setChecklistDashboard] = useState<string>("N/A");
  const [standardIntelligent, setStandardIntelligent] = useState<string>("N/A");


  useEffect(() => {
    let inputs = 0;
    let outputs = 0;
    let surgicalQty = 0;
    let surgicalSize = "N/A";
    let wallQty = 0;
    let wallSize = "N/A";

    config.video.inputs.forEach(input => {
      inputs++;
    });

    config.video.outputs.forEach(output => {
      outputs++;
      if (output.icon.toLowerCase().includes("display") &&
        output.icon.toLowerCase().includes("wall") == false) {
        surgicalQty++;
        if (surgicalSize.includes(output.displaySize) == false) {
          if(surgicalSize == "N/A"){
            surgicalSize = "";
          }
          if (surgicalSize !== "") {
            surgicalSize += ", ";
          }
          surgicalSize += output.displaySize;
        }
      }
      if (output.icon.toLowerCase().includes("display") &&
        output.icon.toLowerCase().includes("wall")) {
        wallQty++;
        if (wallSize.includes(output.displaySize) == false) {
          if(wallSize == "N/A"){
            wallSize = "";
          }
          if (wallSize !== "") {
            wallSize += ", ";
          }
          wallSize += output.displaySize;
        }
      }
    });

    setInputs(inputs);
    setOutputs(outputs);
    setSurgicalQty(surgicalQty);
    setSurgicalSize(surgicalSize);
    setWallQty(wallQty);
    setWallSize(wallSize);
    setMultiview(config.video.multiview);
    setCapture(config.video.capture);
    setStreaming(config.video.streaming ? "Yes" : "No");
    setConferencing(config.video.conferencing ? "Yes" : "No");
    setToms(config.video.toms);
    setChecklistDashboard(config.video.checklistDashboard);
    setStandardIntelligent(config.audio.standardIntelligent);


  }, [config]);

  return (
    <div className='ExecutiveSummaryRow'>
      <div>{config.operatingRoomNumbers}</div>
      <div>{system}</div>
      <div>{inputs}</div>
      <div>{outputs}</div>
      <div>{surgicalQty}</div>
      <div>{surgicalSize}</div>
      <div>{wallQty}</div>
      <div>{wallSize}</div>
      <div>{multiview}</div>
      <div>{capture}</div>
      <div>{streaming}</div>
      <div>{conferencing}</div>
      <div>{toms}</div>
      <div>{checklistDashboard}</div>
      <div>{standardIntelligent}</div>
    </div>
  );
}