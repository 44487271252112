import { type } from "os";
import { SceneObject } from "./SceneObject";
const uuid = require('uuid');

export interface SOW {
	id: string;
	salesRegionID: string;
	name: string;
	type: "OR Room Integration" | "StreamConnect Integration";
	hospitalName: string;
	dateCreated: string;
	configs: Config[];
	departments: Department[];
	leftOverDepartment: Department;
	dateInitiate: string;
	dateDeploy: string;
	dateConfigure: string;
	dateActivate: string;
	dateTrain: string;
	dateSuccess: string;
	deleted?: boolean;
	hasBeenPostProcessed?: boolean;

	config?: Config|Config[];
	externalQuoteId?: string;
	externalQuoteNumber?: string;
	region?: string;
	quoteVersion?: string;
	statementOfWorkId?: string;
}

export interface Config {
	id: string;
	operatingRoomNumbers: string;
	configName?: string;
	boomVendor: string;
	video: {
		system: string;
		lightingControl?:boolean;
		roomToRoom?:boolean;
		chat?:boolean;
		deviceControl?:boolean;
		touchscreenLocation:string;
		multiview: string;
		capture: string;
		streaming?: boolean;
		conferencing?: boolean;
		toms: string;
		checklistDashboard: string;
		inputs: VideoInput[];
		outputs: VideoOutput[];
	};
	audio: {
		standardIntelligent:string;
		inputs: AudioInput[];
		outputs: AudioOutput[];
	};
	roomLayout:{
		type: string;
		scale: number;
		backgroundURL?: string;
		backgroundImageHeight?: number;
		sceneObjects: SceneObject[];
	},
	equipmentBoomLayout:{
		type: string;
		sceneObjects: SceneObject[];
	},
}

export interface VideoInput {
	id: string;
	buttonLocation: string;
	inputsTPButtonText: string;
	icon: string;
	signalOriginates: string;
	ofeSignalType: string;
	kvm?: boolean;
}

export interface VideoOutput {
	id: string;
	buttonLocation: string;
	outputsButtonText: string;
	icon: string;
	signalLocation: string;
	ofeSignalType: string;
	surgicalDisplaySize: string;
	displaySize:string;
}

export interface AudioInput {
	id: string;
	buttonLocation: string;
	inputsTPButtonText: string;
	signalOriginates: string;
};

export interface AudioOutput {
	id: string;
	buttonLocation: string;
	outputsTPButtonText: string;
	signalLocation: string;
};

export interface Department {
	id: string;
	name: string;
	operatingRoomNumbers: string;
	licensingVideoOnDemand: "Purchase"|"Upgrade"|"Existing"|"";
	licensingEHRLINC: "Purchase"|"Upgrade"|"Existing"|"";
	licensingLive: "Purchase"|"Upgrade"|"Existing"|"";
	licensingRoomToRoom: "Purchase"|"Upgrade"|"Existing"|"";
	licensingTOMS: "Purchase"|"Upgrade"|"Existing"|"";
	licensingDashboards: "Purchase"|"Upgrade"|"Existing"|"";
	vodDevices: string;
	vodQuantityAIDA: number;
	vodQuantityTP: number;
	ehrLINCWorklist: boolean;
	ehrLINCArchive: boolean;
	liveEncoderQuantity: number;
	roomToRoomDecoderQuantity: number;
	tomsCameraQuantity: number;
	tomsPlateQuantity: number;
	tomsPofEQuantity: number;
	dashboardsBiomed: boolean;
	dashboardsDevice: boolean;
	strategyMapSceneObjects: SceneObject[];
	isSanArchive?: boolean;
}


export function getDuplicateConfig(config:Config):Config{
	//copy and make new ids for everthing
	let newConfig:Config = JSON.parse(JSON.stringify(config));
	if(config.configName){
		newConfig.configName = config.configName + " Copy";
	}
	newConfig.id = uuid.v4()

	newConfig.video.inputs = newConfig.video.inputs.map((input)=>{
		let newInputID = uuid.v4();
		let oldInputID = input.id;
		input.id = newInputID;
		//go through scene objects and update the labels with the oldInputID
		newConfig.roomLayout.sceneObjects.forEach((sceneObject)=>{
			sceneObject.labels.forEach((label)=>{
				if(label.id == oldInputID){
					label.id = newInputID;
				}
			})
		})
		return input;
	})

	//same for outputs
	newConfig.video.outputs = newConfig.video.outputs.map((output)=>{
		let newOutputID = uuid.v4();
		let oldOutputID = output.id;
		output.id = newOutputID;
		//go through scene objects and update the labels with the oldOutputID
		newConfig.roomLayout.sceneObjects.forEach((sceneObject)=>{
			sceneObject.labels.forEach((label)=>{
				if(label.id == oldOutputID){
					label.id = newOutputID;
				}
			})
		})
		return output;
	})

	//same for audio
	newConfig.audio.inputs = newConfig.audio.inputs.map((input)=>{
		let newInputID = uuid.v4();
		let oldInputID = input.id;
		input.id = newInputID;
		//go through scene objects and update the labels with the oldInputID
		newConfig.roomLayout.sceneObjects.forEach((sceneObject)=>{
			sceneObject.labels.forEach((label)=>{
				if(label.id == oldInputID){
					label.id = newInputID;
				}
			})
		})
		return input;
	})

	//same for audio outputs
	newConfig.audio.outputs = newConfig.audio.outputs.map((output)=>{
		let newOutputID = uuid.v4();
		let oldOutputID = output.id;
		output.id = newOutputID;
		//go through scene objects and update the labels with the oldOutputID
		newConfig.roomLayout.sceneObjects.forEach((sceneObject)=>{
			sceneObject.labels.forEach((label)=>{
				if(label.id == oldOutputID){
					label.id = newOutputID;
				}
			})
		})
		return output;
	})

	//new do equipment boom layout
	newConfig.equipmentBoomLayout.sceneObjects = newConfig.equipmentBoomLayout.sceneObjects.map((sceneObject)=>{
		let newSceneObjectID = uuid.v4();
		sceneObject.id = newSceneObjectID;
		return sceneObject;
	})

	return newConfig;
}



export function getDuplicateDepartment(department:Department):Department{
	//copy and make new ids for everthing
	let newDepartment:Department = JSON.parse(JSON.stringify(department));
	if(department.name){
		newDepartment.name = department.name + " Copy";
	}
	let oldDepartmentID = department.id;
	let newDepartmentID = uuid.v4();
	newDepartment.id = newDepartmentID

	//duplicate any strategyMapSceneObjects that have the oldDepartmentID as their id
	let objectToDuplicate = newDepartment.strategyMapSceneObjects.find((sceneObject)=>{
		return sceneObject.id == oldDepartmentID;
	});
	if(objectToDuplicate){
		let newSceneObject = JSON.parse(JSON.stringify(objectToDuplicate));
		newSceneObject.id = newDepartmentID;
		newDepartment.strategyMapSceneObjects.push(newSceneObject);
	}

	return newDepartment;
}