
import React, { useRef, useState, useEffect } from 'react';
import './ScreenStreamConnectSystemSetup.css';
import { Config, Department, SOW, getDuplicateDepartment } from '../../../Model/SOW';
import NavigationHeader from '../../../Components/NavigationHeader/NavigationHeader';
import ConfigurationTab from '../../../Components/Components_OR/ConfigurationTab/ConfigurationTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import ConfigurationTabContent from '../../../Components/Components_OR/ConfigurationTabContent/ConfigurationTabContent';
import { saveSOW } from '../../../Utils/EmailSaveAndLoad';
import { blankConfig, blankDepartment } from '../../../Utils/BlankObjects';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../Redux/ActionCreators';
import { Screens } from '../../../Redux/Reducers';

import leftBar from '../../Images/ui/left_bar@2x.png';
import leftBarTop from '../../Images/ui/left_bar_top@2x.png';
import leftBarBottom from '../../Images/ui/left_bar_bottom@2x.png';
import rebuildRoomConfiguratorSceneObjects from '../../../Utils/RebuildRoomConfiguratorSceneObjects';
import PopupConfirm from '../../../Components/PopupConfirm/PopupConfirm';
import { JsxElement } from 'typescript';
import DepartmentTab from '../../../Components/Components_StreamConnect/DepartmentTab/DepartmentTab';
import DepartmentTabContent from '../../../Components/Components_StreamConnect/DepartmentTabContent/DepartmentTabContent';
import RebuildDepartmentStrategyMapSceneObjects from '../../../Utils/RebuildDepartmentStrategyMapSceneObjects';
import { SalesRegion } from '../../../Model/SalesRegion';
import { collection, DocumentData, CollectionReference, Query, where, onSnapshot, query } from 'firebase/firestore';
import Dropdown from '../../../Components/Dropdown/Dropdown';
import { sharedFirestore } from '../../../Utils/SharedFirebase';
import { KarlStorzUser } from '../../../Model/KarlStorzUser';

interface ScreenStreamConnectSystemSetupProps {
	sow: SOW;
}

let confirmPopupCallback = () => {

}

export default function ScreenStreamConnectSystemSetup({ sow }: ScreenStreamConnectSystemSetupProps) {
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		setSOW: ActionCreators.setSOW,
		setCurrentDepartmentIndex: ActionCreators.setCurrentDepartmentIndex,
	}, dispatch);

	const [localSOW, setLocalSOW] = useState<SOW>(sow);
	const loggedInUser = useSelector((state: { loggedInUser: KarlStorzUser }) => state.loggedInUser)
	const currentDepartmentIndex = useSelector((state: { currentDepartmentIndex: number }) => state.currentDepartmentIndex)


	const [showingUnfilled, setShowingUnfilled] = useState<boolean>(false);
	const [departments, setDepartments] = useState<Department[]>(localSOW.departments);
	const [selectedDepartment, setSelectedDepartment] = useState<Department>(departments[currentDepartmentIndex]);
	const [selectedDepartmentName, setSelectedDepartmentName] = useState<string>(selectedDepartment.name);

	const [switchingConfigs, setSwitchingConfigs] = useState<boolean>(false);
	const [refreshingTabBar, setRefreshingTabBar] = useState<boolean>(false);

	const [showingConfirmPopup, setShowingConfirmPopup] = useState<boolean>(false);
	const [confirmPopupText, setConfirmPopupText] = useState<string | JSX.Element>("");
	const [confirmPopupCancelText, setConfirmPopupCancelText] = useState<string | undefined>(undefined);
	const [confirmPopupConfirmText, setConfirmPopupConfirmText] = useState<string | undefined>(undefined);

	const canNavigate = (departments: Department[]) => {
		let unfilled = false;
		departments.forEach(department => {
			if (department.operatingRoomNumbers == "") {
				unfilled = true;
			}
			if (department.name == "") {
				unfilled = true;
			}

		});

		return !unfilled;
	}


	useEffect(() => {
		if (departments.length != localSOW.departments.length) {
			if (departments.length > 0 && !selectedDepartment) {
				console.log("localSOW updated");
				setSelectedDepartment(departments[0]);
			}
		}
	}, [localSOW, localSOW.departments]);

	useEffect(() => {
		console.log("configs updated")
		if (selectedDepartment) {
			let found = departments.find(c => c.id == selectedDepartment.id) !== undefined;
			if (!found) {
				setSelectedDepartment(departments[0]);
			}
			setSelectedDepartmentName(selectedDepartment.name);
		}
	}, [departments, selectedDepartment]);

	useEffect(() => {
		console.log("config selected")
		setSwitchingConfigs(true);
	}, [selectedDepartment]);

	useEffect(() => {
		if (switchingConfigs) {
			setTimeout(() => {
				console.log("end switching configs")
				setSwitchingConfigs(false);
			}, 10);
		}
	}, [switchingConfigs]);

	useEffect(() => {
		if(refreshingTabBar){
			setTimeout(() => {
				setRefreshingTabBar(false);
			}, 10);
		}
	},[refreshingTabBar]);

	const [salesRegions, setSalesRegions] = useState([] as SalesRegion[]);
	const [selectedRegionID, setSelectedReginID] = useState(sow.salesRegionID ?? "");
	const salesRegionsCollectionRef = collection(sharedFirestore, "SalesRegions");

	useEffect(() => {

		let q: Query<DocumentData, DocumentData> | CollectionReference<DocumentData, DocumentData>;
		q = query(salesRegionsCollectionRef, where("id", "in", loggedInUser.salesRegionIDs));
		if (loggedInUser.isAdmin) {
			q = salesRegionsCollectionRef;
		}

		const unsubscribeSalesRegions = onSnapshot(q, (querySnapshot) => {
			let salesRegions = [] as SalesRegion[];
			querySnapshot.forEach((doc) => {
				let docData = doc.data();
				let salesRegion: SalesRegion = {
					id: doc.id,
					name: docData.name,
					sowIDs: docData.sowIDs,
				}
				salesRegions.push(salesRegion);
			});
			if (salesRegions.length == 1) {
				setSelectedReginID(localSOW.salesRegionID ?? salesRegions[0].id);
			}
			if (salesRegions.length > 0) {
				setSalesRegions(salesRegions);
			}
		});
		return () => {
			unsubscribeSalesRegions();
		}
	}, [localSOW])

	useEffect(() => {
		if (selectedRegionID != "" && localSOW.salesRegionID != selectedRegionID) {
			localSOW.salesRegionID = selectedRegionID;
			saveSOW(localSOW).then(() => {
				setLocalSOW(localSOW);
			});
		}
	}, [selectedRegionID, localSOW])

	return (
		<>
			<div className={`Screen ScreenStreamConnectSystemSetup ${showingUnfilled ? "showingUnfilled" : ""}`}>
				<div className='leftBar' />
				<div className='verticalLabel'>System Setup</div>
				<div className='configSetupheader'>
					{sow.hospitalName}
					<div className='tabBar'>
						{departments.map((department, index) => {
							return <DepartmentTab
								department={department}
								departmentNum={index + 1}
								selected={department.id == selectedDepartment?.id}
								onChange={(department) => {
									const index = localSOW.departments.findIndex(c => c.id == department.id);
									localSOW.departments[index] = department;
									saveSOW(localSOW).then(() => {
										setDepartments(localSOW.departments);
										setSwitchingConfigs(true);
										//setSelectedConfig(config);
									});
								}}
								onSelect={(department) => {
									setSelectedDepartment(department);
								}}
								onDuplicate={(department) =>{
									let duplicateDepartement =getDuplicateDepartment(department);
									let newIndex = index + 1;
									localSOW.departments.splice(newIndex,0,duplicateDepartement);
									saveSOW(localSOW).then(() => {
										setDepartments(localSOW.departments);
										AC.setCurrentDepartmentIndex(newIndex);
										//setSelectedConfig(config);
									});
								}} />;
						})}
						<div className="buttonAddConfig"
							onClick={(e) => {
								e.stopPropagation();
								let department = blankDepartment();
								department.name = (localSOW.departments.length % 2 == 0) ? "Main OR" : "ENT Clinic";
								localSOW.departments.push(department);
								saveSOW(localSOW).then(() => {
									setDepartments(localSOW.departments);
									AC.setCurrentDepartmentIndex(localSOW.departments.length - 1);
									//setSelectedConfig(config);
								});
							}} />
					</div>
				</div>

				<div className='floatingTopRight'>
					
				{localSOW.externalQuoteId && <>
						<div className='col'>
							<div className='label'>Salesforce Quote ID</div>
							<div className='value'>{localSOW.externalQuoteId}</div>
						</div>
					</>}
					{localSOW.externalQuoteNumber && <>
						<div className='col'>
							<div className='label'>Quote Number</div>
							<div className='value'>{localSOW.externalQuoteNumber}</div>
						</div>
					</>}
					{localSOW.quoteVersion && <>
						<div className='col'>
							<div className='label'>Version</div>
							<div className='value'>{localSOW.quoteVersion}</div>
						</div>
					</>}

					{salesRegions.length > 1 && <>
						<div className='col'>
							<div className='label'>Sales Region</div>

							<Dropdown
								className='dropdownSalesRegion'
								options={salesRegions.map((salesRegion) => { return salesRegion.name })}
								defaultValue={salesRegions.find(salesRegion => salesRegion.id == selectedRegionID)?.name ?? "No Sales Region Selected"}
								onSelect={(option,index) => {
									setSelectedReginID(salesRegions[index].id);
								}}></Dropdown>
						</div>
					</>}
				</div>
				{switchingConfigs == false && (
					<>
						<DepartmentTabContent
							department={selectedDepartment}
							departmentNumber={localSOW.departments.indexOf(selectedDepartment) + 1}
							onDepartmentChange={(department: Department) => {
								const index = localSOW.departments.findIndex(c => c.id == department.id);
								localSOW.departments[index] = department;
								if(department.name != selectedDepartmentName){
									setRefreshingTabBar(true);
									setSelectedDepartmentName(department.name);
								}
								RebuildDepartmentStrategyMapSceneObjects(localSOW).then((sow: SOW) => {
									setLocalSOW(sow);
									//AC.setSOW(sow);
									//setConfigs([...sow.configs]);
								});
							}}
							onDepartmentDeleted={(department: Department) => {
								setConfirmPopupText(<>Delete <b>{department.name??("Department " + departments.findIndex(c => c.id == department.id) + 1)}?</b></>);
								setConfirmPopupCancelText("Don't Delete");
								setConfirmPopupConfirmText("Yes, Delete");
								confirmPopupCallback = (() => {
									let id = department.id;

									localSOW.departments.forEach((department) => {
										let newSceneObjects = department.strategyMapSceneObjects;
										// delete all sceneobjects where the parentID = id
										for (let i = newSceneObjects.length - 1; i >= 0; i--) {
											let sceneObject = newSceneObjects[i];
											if (sceneObject.parentID === id) {
												let childIndex = sceneObject.children.findIndex((obj) => obj.id === id);
												if (childIndex != -1) {
													sceneObject.children.splice(childIndex, 1);
												}
												newSceneObjects.splice(i, 1);
											}
											if (sceneObject.id == id) {
												newSceneObjects.splice(i, 1);
											}
										}
									})


									const index = localSOW.departments.findIndex(c => c.id == department.id);
									localSOW.departments.splice(index, 1);
									setLocalSOW(localSOW);
									//AC.setSOW(sow);
									setDepartments(localSOW.departments);
									let newIndex = localSOW.departments.length < index ? index : index - 1
									AC.setCurrentDepartmentIndex(newIndex);
									//setSelectedConfig(localSOW.configs.length < index?localSOW.configs[index]:localSOW.configs[index-1]);
								});
								setShowingConfirmPopup(true);
							}}
						/>

					</>
				)}
			</div>
			<NavigationHeader
				sow={localSOW}
				hideConfigDropdown={true}
				beforeNavigateAway={() => {
					AC.setSOW(localSOW);
				}}
				onNext={(screen?: Screens, departmentIndex?: number) => {
					if (canNavigate(localSOW.departments)) {
						if (screen !== undefined && departmentIndex !== undefined) {
							AC.setCurrentDepartmentIndex(departmentIndex);
							AC.setScreen(screen);
						}
						else {
							AC.setScreen(Screens.StreamConnectTableOfContents);
						}
					} else {
						setShowingUnfilled(true);
						setConfirmPopupText("Please fill out all entries")
						setConfirmPopupCancelText(undefined);
						setConfirmPopupConfirmText("Okay");
						confirmPopupCallback = (() => {
							setShowingConfirmPopup(false);
						});
						setShowingConfirmPopup(true);
					}
				}} />
			{showingConfirmPopup && (
				<PopupConfirm
					text={confirmPopupText}
					cancelText={confirmPopupCancelText}
					confirmText={confirmPopupConfirmText}
					onCancel={() => {
						setShowingConfirmPopup(false);
					}}
					onConfirm={() => {
						setShowingConfirmPopup(false);
						if (confirmPopupCallback) {
							confirmPopupCallback();
						}
					}} />
			)}
		</>
	);
}