
import React, { useRef, useState, useEffect } from 'react';
import './ScreenLogin.css';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../Redux/ActionCreators';
import { Screens } from '../../Redux/Reducers';
import { CircularProgress } from "@mui/material";
import { createUserWithEmailAndPassword, setPersistence, browserLocalPersistence, signInWithEmailAndPassword, sendPasswordResetEmail, signInWithCustomToken } from 'firebase/auth';
import { sharedFirebaseAuth, sharedFirestore } from '../../Utils/SharedFirebase';
import { collection, doc, setDoc, updateDoc } from 'firebase/firestore';
import { msalInstance } from '../../Utils/AuthConfig';

const uuid = require('uuid');

msalInstance.initialize()


interface ScreenLoginProps {
}
export default function ScreenLogin({ }: ScreenLoginProps) {

	const [registering, setRegistering] = useState(false);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [error, setError] = useState("");

	const [showingCircularProgress, setShowingCircularProgress] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			setShowingCircularProgress(false);
		}, 2000);
	}, []);

	const handleLoginWithAzure = async () => {

		try {
			const result = await msalInstance.loginPopup({
				scopes: ["User.Read"]  // scopes for ID token + basic Graph data
			});
			// AuthenticationResult returned
			console.log("Azure AD login successful:", result);

			// You can extract the ID token JWT and account info from the result:
			const idToken = result.idToken;  // JWT string representing the user's ID token
			const user = result.account;    // account info (username, tenant, etc.)
			console.log("ID Token:", idToken);
			console.log("User account:", user);

			const uid = (user.idTokenClaims?.oid || user.idTokenClaims?.sub) ?? "";
			const email = (user.idTokenClaims?.email || user.idTokenClaims?.upn || user.username.toLowerCase()) ?? "";
			const name = (user.name) ?? "";

			fetch("https://us-central1-karlstorz-dynamicsow.cloudfunctions.net/createFirebaseToken", {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					uid: uid,
					email: email,
					name: name
				})
			}).then((response) => response.json()).then((data) => {
				if (data.token) {
					console.log("firebase token created")
					signInWithCustomToken(sharedFirebaseAuth, data.token)
				}else{
					console.log("failed to create firebase token")
				}
			}).catch((error) => {
				console.error("Firebase token creation failed:", error);
			});

			// (We will use idToken to auth with Firebase in the next step)
		} catch (error) {
			console.error("Azure AD login failed:", error);
			// Handle popup closed or login error
		}
	}


	return (
		<div className='Screen ScreenLogin'>

			{showingCircularProgress ? <>
				<CircularProgress />
			</> : <>
				<div className='content'>
					{error != "" && <div className="error">{error}</div>}
					{/* {registering ? <form onSubmit={() => {
						let emailParts = email.split('@');
						if (emailParts[1].toLowerCase() != 'karlstorz.com' && emailParts[1].toLowerCase() != 'infusemed.com') {
							setError("Invalid email address");
							return;
						}
						createUserWithEmailAndPassword(sharedFirebaseAuth, email.toLowerCase(), password)
							.then((userCredential) => {
								// Signed in 
								const user = userCredential.user;

								// Save an entry in the Users table for this user. non admin, no sales region
								let data = {
									id: uuid.v4(),
									email: email.toLowerCase(),
									isAdmin: false,
									salesRegionID: "",
								}

								// Add a new document with a generated id.
								const personsCollectionRef = collection(sharedFirestore, "Users");
								let docRef = doc(personsCollectionRef, data.id);
								setDoc(docRef, data);

							})
							.catch((error) => {
								const errorCode = error.code;
								const errorMessage = error.message;
								setError(errorMessage);
								setShowingCircularProgress(false);
							});
						setShowingCircularProgress(true);
					}}>
						<h1>Register</h1>
						<h2>Email</h2>
						<input type="text" placeholder="Email" onChange={(event) => { setEmail(event.target.value) }} />
						<h2>Password</h2>
						<input type="password" placeholder="Password" onChange={(event) => { setPassword(event.target.value) }} />
						<h2>Confirm Password</h2>
						<input type="password" placeholder="Confirm Password" onChange={(event) => { setConfirmPassword(event.target.value) }} />
						<input type='submit' className="button" value={"Register"} ></input>
						<p className="register">
							Already have an account?<div className='buttonRegister' onClick={() => { setRegistering(false) }}>Login</div>
						</p>
					</form> : <>
						<form onSubmit={() => {
							setPersistence(sharedFirebaseAuth, browserLocalPersistence)
								.then(() => {
									signInWithEmailAndPassword(sharedFirebaseAuth, email.toLowerCase(), password)
										.then((userCredential) => {
											// Signed in 
											const user = userCredential.user;
											// ...
										})
										.catch((error) => {
											const errorCode = error.code;
											const errorMessage = error.message;
											setError(errorMessage);
											setShowingCircularProgress(false);
										});

									setShowingCircularProgress(true);
								})

						}}>
							<h1>Login</h1>
							<h2>Email</h2>
							<input type="text" placeholder="Email" onChange={(event) => { setEmail(event.target.value) }} />
							<h2>Password</h2>
							<input type="password" placeholder="Password" onChange={(event) => { setPassword(event.target.value) }} />

							<div className='buttonForgotPassword' onClick={() => {
								sendPasswordResetEmail(sharedFirebaseAuth, email).then(() => {
									alert("Email sent");
								});
							}}>Forgot Password?</div>
							<input type="submit" className="button" value={"Login"}></input>
							<p className="register">
								Don't have an account? <div className='buttonRegister' onClick={() => { setRegistering(true) }}>Register</div>
							</p>
						</form>
					</>} */}

					<div className='logoKS'></div>
					<div className='buttonLoginWithAzure' onClick={handleLoginWithAzure}>Login with Azure</div>
				</div>
			</>}

		</div>
	);
}