import { SOW } from "../Model/SOW";
import { blankDepartment, blankVideoInput, blankVideoOutput } from "./BlankObjects";
import rebuildRoomConfiguratorSceneObjects from "./RebuildRoomConfiguratorSceneObjects";
const uuid = require('uuid');


export default async function PostProcessSOW(sow: SOW) {
	return new Promise(async (resolve: (sow: SOW) => void, reject) => {

        // if(sow.hasBeenPostProcessed){
        //     resolve(sow);
        //     return;
        // }

        sow.hasBeenPostProcessed = true;

        let todaysDate = new Date();
        if(!sow.dateActivate){
            sow.dateActivate = todaysDate.toLocaleDateString('en-US', {month: '2-digit', year: '2-digit', day: '2-digit'});
        }
        if(!sow.dateConfigure){
            sow.dateConfigure = todaysDate.toLocaleDateString('en-US', {month: '2-digit', year: '2-digit', day: '2-digit'});
        }
        if(!sow.dateDeploy){
            sow.dateDeploy = todaysDate.toLocaleDateString('en-US', {month: '2-digit', year: '2-digit', day: '2-digit'});
        }
        if(!sow.dateSuccess){
            sow.dateSuccess = todaysDate.toLocaleDateString('en-US', {month: '2-digit', year: '2-digit', day: '2-digit'});
        }
        if(!sow.dateTrain){
            sow.dateTrain = todaysDate.toLocaleDateString('en-US', {month: '2-digit', year: '2-digit', day: '2-digit'});
        }
        
        if(!sow.departments){
            sow.departments = [];
        }
        if(!sow.configs){
            if(sow.config){
                if(sow.config instanceof Array){
                    sow.configs = [...sow.config];
                } else {
                    sow.configs = [sow.config];
                }
            } else {
                sow.configs = [];
            }
        }
        if(sow.config && sow.configs.length == 0){
            if(sow.config instanceof Array){
                sow.configs = [...sow.config];
            } else {
                sow.configs = [sow.config];
            }
            delete sow.config;
        }
        if(!sow.leftOverDepartment){
            sow.leftOverDepartment = blankDepartment();
        }

        if(!sow.salesRegionID){
            sow.salesRegionID = "";
        }
        

        if(sow.type == "OR Room Integration"){
            for(let i = 0; i < sow.configs.length; i++){
                let config = sow.configs[i];

                /////////////////////////////// Begin adding defaults ///////////////////////////////
                if(!config.id){
                    config.id = uuid.v4();
                }
                if(!config.boomVendor){
                    config.boomVendor = "";
                }
                if(!config.operatingRoomNumbers){
                    config.operatingRoomNumbers = "";
                }
                if(!config.video){
                    config.video = {
                        system: '',
                        lightingControl: undefined,
                        roomToRoom: undefined,
                        chat: undefined,
                        deviceControl: undefined,
                        touchscreenLocation: "",
                        multiview: "",
                        capture: "",
                        streaming: undefined,
                        conferencing: undefined,
                        toms: "",
                        checklistDashboard: "",
                        inputs: [],
                        outputs: []
                    }
                }
                if(!config.video.system){
                    config.video.system = "";
                }
                if(!config.video.touchscreenLocation){
                    config.video.touchscreenLocation = "";
                }
                if(!config.video.multiview){
                    config.video.multiview = "";
                }
                if(!config.video.capture){
                    config.video.capture = "";
                }
                if(!config.video.toms){
                    config.video.toms = "";
                }
                if(!config.video.checklistDashboard){
                    config.video.checklistDashboard = "";
                }
                if(!config.video.inputs){
                    config.video.inputs = [];
                }
                if(!config.video.outputs){
                    config.video.outputs = [];
                }
                
                
                if(!config.audio){
                    config.audio = {
                        standardIntelligent: '',
                        inputs: [],
                        outputs: []
                    }
                }
                if(!config.audio.standardIntelligent){
                    config.audio.standardIntelligent = "";
                }
                if(!config.audio.inputs){
                    config.audio.inputs = [];
                }
                if(!config.audio.outputs){
                    config.audio.outputs = [];
                }
                
                if(!config.roomLayout){
                    config.roomLayout = {
                        type: 'square',
                        scale: 0.75,
                        sceneObjects: []
                    }
                }
                if(!config.roomLayout.type){
                    config.roomLayout.type = "square";
                }
                if(!config.roomLayout.scale){
                    config.roomLayout.scale = 0.75;
                }
                if(!config.roomLayout.sceneObjects){
                    config.roomLayout.sceneObjects = [];
                }
                for(let j = 0; j < config.roomLayout.sceneObjects.length; j++){
                    let sceneObject = config.roomLayout.sceneObjects[j];
                    if(!sceneObject.id){
                        sceneObject.id = uuid.v4();
                    }
                    if(!sceneObject.type){
                        sceneObject.type = "";
                    }
                    if(!sceneObject.subtype){
                        sceneObject.subtype = "";
                    }
                    if(!sceneObject.letter){
                        sceneObject.letter = "";
                    }
                    if(!sceneObject.name){
                        sceneObject.name = "";
                    }
                    if(!sceneObject.visible){
                        sceneObject.visible = true;
                    }
                    if(!sceneObject.locked){
                        sceneObject.locked = false;
                    }
                    if(!sceneObject.scale){
                        sceneObject.scale = 1;
                    }
                    if(!sceneObject.left){
                        sceneObject.left = 50;
                    }
                    if(!sceneObject.top){
                        sceneObject.top = 50;
                    }
                    if(!sceneObject.rotation){
                        sceneObject.rotation = 0;
                    }
                    if(!sceneObject.flipped){
                        sceneObject.flipped = false;
                    }
                    if(!sceneObject.addedInRoom){
                        sceneObject.addedInRoom = false;
                    }
                    if(!sceneObject.labels){
                        sceneObject.labels = [];
                    }
                    if(!sceneObject.children){
                        sceneObject.children = [];
                    }
                    
                }
                
                if(!config.equipmentBoomLayout){
                    config.equipmentBoomLayout = {
                        type: '',
                        sceneObjects: []
                    }
                }

                for(let j = 0; j < config.video.inputs.length; j++){
                    let input = config.video.inputs[j];
                    if(!input.id){
                        input.id = uuid.v4();
                    }
                    if(!input.buttonLocation || input.buttonLocation == ""){
                        input.buttonLocation = (j + 1) + "";
                    }
                    if(!input.inputsTPButtonText){
                        input.inputsTPButtonText = "";
                    }
                    if(!input.icon){
                        input.icon = "";
                    }
                    if(!input.signalOriginates){
                        input.signalOriginates = "";
                    }
                    if(!input.ofeSignalType){
                        input.ofeSignalType = "";
                    }
                    if(!input.kvm){
                        input.kvm = false;
                    }
                }

                for(let j = 0; j < config.video.outputs.length; j++){
                    let output = config.video.outputs[j];
                    if(!output.id){
                        output.id = uuid.v4();
                    }
                    if(!output.buttonLocation || output.buttonLocation == ""){
                        output.buttonLocation = (j + 1) + "";
                    }
                    if(!output.outputsButtonText){
                        output.outputsButtonText = "";
                    }
                    if(!output.icon){
                        output.icon = "";
                    }
                    if(!output.signalLocation){
                        output.signalLocation = "";
                    }
                    if(!output.ofeSignalType){
                        output.ofeSignalType = "";
                    }
                    if(!output.surgicalDisplaySize){
                        output.surgicalDisplaySize = "";
                    }
                    if(!output.displaySize){
                        output.displaySize = "";
                    }
                }

                for(let j = 0; j < config.audio.inputs.length; j++){
                    let input = config.audio.inputs[j];
                    if(!input.id){
                        input.id = uuid.v4();
                    }
                    if(!input.buttonLocation || input.buttonLocation == ""){
                        input.buttonLocation = (j + 1) + "";
                    }
                    if(!input.inputsTPButtonText){
                        input.inputsTPButtonText = "";
                    }
                    if(!input.signalOriginates){
                        input.signalOriginates = "";
                    }
                    
                    
                }

                for(let j = 0; j < config.audio.outputs.length; j++){
                    let output = config.audio.outputs[j];
                    if(!output.id){
                        output.id = uuid.v4();
                    }
                    if(!output.buttonLocation || output.buttonLocation == ""){
                        output.buttonLocation = (j + 1) + "";
                    }
                    if(!output.outputsTPButtonText){
                        output.outputsTPButtonText = "";
                    }
                    if(!output.signalLocation){
                        output.signalLocation = "";
                    }
                    
                }

                /////////////////////////////// End adding defaults ///////////////////////////////





                if(config.video.conferencing == true){
                    if(config.video.outputs.length == 0){
                        let blankVideo = blankVideoOutput();
                        blankVideo.buttonLocation = (config.video.outputs.length + 1) + "";
                        config.video.outputs.push(blankVideo);
                    }
                    if(config.video.inputs.length == 0){
                        let blankVideo = blankVideoInput();
                        blankVideo.buttonLocation = (config.video.inputs.length + 1) + "";
                        config.video.inputs.push(blankVideo);
                    }
                    
                }
                if(config.video.capture == "Dual"){
                    if(config.video.outputs.length <2){
                        let blankVideo = blankVideoOutput();
                        blankVideo.buttonLocation = (config.video.outputs.length + 1) + "";
                        blankVideo.outputsButtonText = "RECORDING CH.1";
                        config.video.outputs.push(blankVideo);

                        blankVideo = blankVideoOutput();
                        blankVideo.buttonLocation = (config.video.outputs.length + 1) + "";
                        blankVideo.outputsButtonText = "RECORDING CH.2";
                        config.video.outputs.push(blankVideo);
                    }
                }
            }
            await rebuildRoomConfiguratorSceneObjects(sow);
        }

        resolve(sow);

	});
}