import React, { useEffect, useState } from 'react';
import './Fonts/style.css';
import './App.css';
import './Screens/Screens.css';
import { useDispatch, useSelector } from 'react-redux';
import { Screens } from './Redux/Reducers';
import ScreenSplash from './Screens/ScreenSplash/ScreenSplash';
import ScreenLanding from './Screens/ScreenLanding/ScreenLanding';
import ScreenLoadSOW from './Screens/ScreenLoadSOW/ScreenLoadSOW';
import { Config, Department, SOW } from './Model/SOW';
import ScreenORConfigSetup from './Screens/ScreenOR/ScreenORConfigSetup/ScreenORConfigSetup';
import ScreenORContents from './Screens/ScreenOR/ScreenORContents/ScreenORContents';
import ScreenORExecutiveSummary from './Screens/ScreenOR/ScreenORExecutiveSummary/ScreenORExecutiveSummary';
import ScreenOrProjectRoadmap from './Screens/ScreenOR/ScreenOrProjectRoadmap/ScreenOrProjectRoadmap';
import ScreenORRoomLayout from './Screens/ScreenOR/ScreenORRoomLayout/ScreenORRoomLayout';
import ScreenORTouchscreenLayout from './Screens/ScreenOR/ScreenORTouchscreenLayout/ScreenORTouchscreenLayout';
import ScreenORInputOutputSchedule from './Screens/ScreenOR/ScreenORInputOutputSchedule/ScreenORInputOutputSchedule';
import ScreenOREquipmentBoomLayout from './Screens/ScreenOR/ScreenOREquipmentBoomLayout/ScreenOREquipmentBoomLayout';
import ScreenORBegin from './Screens/ScreenOR/ScreenORBegin/ScreenORBegin';
import PDFOutput from './Components/PDFOutput/PDFOutput';
import { bindActionCreators } from 'redux';
import ActionCreators from './Redux/ActionCreators';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ScreenPDFOutputOR from './Screens/ScreenOR/ScreenPDFOutputOR/ScreenPDFOutputOR';
import ScreenPDFViewer from './Screens/ScreenPDFViewer/ScreenPDFViewer';
import ScreenStreamConnectBegin from './Screens/ScreenStreamConnect/ScreenStreamConnectBegin/ScreenStreamConnectBegin';
import ScreenStreamConnectSystemSetup from './Screens/ScreenStreamConnect/ScreenStreamConnectSystemSetup/ScreenStreamConnectSystemSetup';
import StreamConnectEnterpriseStrategyMap from './Screens/ScreenStreamConnect/StreamConnectEnterpriseStrategyMap/StreamConnectEnterpriseStrategyMap';
import StreamConnectTableOfContents from './Screens/ScreenStreamConnect/StreamConnectTableOfContents/StreamConnectTableOfContents';
import StreamConnectExecutiveSummary from './Screens/ScreenStreamConnect/StreamConnectExecutiveSummary/StreamConnectExecutiveSummary';
import StreamConnectScopeOfWork from './Screens/ScreenStreamConnect/StreamConnectScopeOfWork/StreamConnectScopeOfWork';
import StreamConnectProjectRoadmap from './Screens/ScreenStreamConnect/StreamConnectProjectRoadmap/StreamConnectProjectRoadmap';
import StreamConnectTopologyDiagram from './Screens/ScreenStreamConnect/StreamConnectTopologyDiagram/StreamConnectTopologyDiagram';
import ScreenStreamConnectDates from './Screens/ScreenStreamConnect/ScreenStreamConnectDates/ScreenStreamConnectDates';
import ScreenPDFOutputStreamConnect from './Screens/ScreenStreamConnect/ScreenPDFOutputStreamConnect/ScreenPDFOutputStreamConnect';
import StreamConnectScopeOfWorkUpgrades from './Screens/ScreenStreamConnect/StreamConnectScopeOfWork/StreamConnectScopeOfWork';
import { KarlStorzUser } from './Model/KarlStorzUser';
import ScreenLogin from './Screens/ScreenLogin/ScreenLogin';
import ScreenAdmin from './Screens/ScreenAdmin/ScreenAdmin';
import { AuthContext } from './Auth/AuthContext';
import ScreenNoSalesRegion from './Screens/ScreenNoSalesRegion/ScreenNoSalesRegion';
import { useContext } from 'react';
import { GetKarlStorzUser } from './Utils/GetKarlStorzUser';
import { query } from 'express';
import { collection, where, getDocs, getDoc, doc, setDoc } from 'firebase/firestore';
import PostProcessSOW from './Utils/PostProcessSOW';
import { sharedFirestore } from './Utils/SharedFirebase';


export const SOWS_TABLE_NAME_PROD: string = "SOWs";
export const SOWS_TABLE_NAME_DEV: string = "SOWs_dev";

export const SOWS_TABLE_NAME: string = SOWS_TABLE_NAME_PROD;

const uuid = require('uuid');


function App() {
  const currentScreen = useSelector((state: { screen: Screens }) => state.screen)
  const printingPDF = useSelector((state: { printingPDF: boolean }) => state.printingPDF)
  const currentSOW = useSelector((state: { sow: SOW }) => state.sow)
  const currentConfigOrDepartmentIndex = useSelector((state: { currentConfigIndex: number }) => state.currentConfigIndex)
  const loggedInUser = useSelector((state: { loggedInUser: KarlStorzUser | null }) => state.loggedInUser)

  const firebaseUser = useContext(AuthContext);
  useEffect(() => {
    
    const completeLogin = (user: KarlStorzUser | null) => {
      if (user) {
        AC.setLoggedInUser(user);

        setTimeout(() => {
          const urlParams = new URLSearchParams(window.location.search);
          const sowID = urlParams.get('sow_id');
          if (sowID) {
            // Load the SOW with this ID
            const sowsCollectionRef = collection(sharedFirestore, SOWS_TABLE_NAME);
            const docref = doc(sowsCollectionRef, sowID);
            getDoc(docref).then((doc) => {
              if (!doc.exists()) {
                return;
              }
              let sowData = doc.data() as SOW;
              PostProcessSOW(sowData).then((sowData) => {
                AC.setSOW(sowData);
                if (sowData.type == "OR Room Integration") {
                  AC.setScreen(Screens.ORConfigSetup);
                } else {
                  AC.setScreen(Screens.StreamConnectSystemSetup);
                }
              });
            });
          }
        }, 2000);
      }
    }

    if (firebaseUser) {
      console.log("firebaseUser", firebaseUser);
      GetKarlStorzUser(firebaseUser.email!).then((user: KarlStorzUser | null) => {
        if (user) {
          completeLogin(user);
        } else {

          let data = {
            id: uuid.v4(),
            email: firebaseUser.email!.toLowerCase(),
            isAdmin: false,
            salesRegionID: "",
          }

          // Add a new document with a generated id.
          const personsCollectionRef = collection(sharedFirestore, "Users");
          let docRef = doc(personsCollectionRef, data.id);
          setDoc(docRef, data);
          GetKarlStorzUser(firebaseUser.email!).then((user: KarlStorzUser | null) => {
            if (user) {
              completeLogin(user);
            }
          });
        }
      })
    } else {
      AC.setLoggedInUser(null);
    }
  }, [firebaseUser]);

  const [switchingConfig, setSwitchingConfig] = useState<boolean>(false);
  useEffect(() => {
    if (switchingConfig) {
      setTimeout(() => {
        setSwitchingConfig(false);
      }, 100);
    }
  }, [switchingConfig]);

  useEffect(() => {
    setSwitchingConfig(true);
  }, [currentConfigOrDepartmentIndex]);

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setPrintingPDF: ActionCreators.setPrintingPDF,
    setLoggedInUser: ActionCreators.setLoggedInUser,
    setSOW: ActionCreators.setSOW,
    setScreen: ActionCreators.setScreen,
  }, dispatch);

  const [pdfData, setPdfData] = useState<any>(null);

  const [scale, setScale] = React.useState(1);

  useEffect(() => {
    const handleResize = () => {
      let width = window.innerWidth;
      let height = window.innerHeight;
      //fit it so that 1920x1080 will fit on the screen
      let scale = Math.min(width / 1920, height / 1080);

      setScale(scale);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);


  const whichScreen = (loggedInUser: KarlStorzUser | null) => {

    if (loggedInUser == null) {
      return <ScreenLogin />
    }
    let nonNullSalesRegions = loggedInUser.salesRegionIDs.filter((sr) => (sr != null && sr != ""));
    if (!loggedInUser.isAdmin && nonNullSalesRegions.length == 0) {
      return <ScreenNoSalesRegion />
    }

    let config: Config | null = null;
    let department: Department | null = null;
    if (currentSOW) {
      if (currentSOW.departments.length > 0) {
        department = currentSOW.departments[currentConfigOrDepartmentIndex];
      }
      if (currentSOW.configs.length > 0) {
        config = currentSOW.configs[currentConfigOrDepartmentIndex];
      }
    }
    switch (currentScreen) {
      case Screens.Splash:
        return <ScreenSplash />
      case Screens.Landing:
        return <ScreenLanding />
      case Screens.Admin:
        return <ScreenAdmin />
      case Screens.LoadSOW:
        return <ScreenLoadSOW />
      case Screens.ORBegin:
        return <ScreenORBegin />
      case Screens.ORConfigSetup:
        return <ScreenORConfigSetup sow={currentSOW} />
      case Screens.ORContents:
        return <ScreenORContents sow={currentSOW} forPDF={false} />
      case Screens.ORExecutiveSummary:
        return <ScreenORExecutiveSummary sow={currentSOW} forPDF={false} />
      case Screens.ORProjectRoadMap:
        return <ScreenOrProjectRoadmap sow={currentSOW} forPDF={false} />
      case Screens.ORRoomLayout:
        return <ScreenORRoomLayout sow={currentSOW} forPDF={false} config={config ?? undefined} />
      case Screens.ORTouchscreenLayout:
        return <ScreenORTouchscreenLayout sow={currentSOW} forPDF={false} config={config ?? undefined} />
      case Screens.ORInputOutputSchedule:
        return <ScreenORInputOutputSchedule sow={currentSOW} forPDF={false} config={config ?? undefined} />
      case Screens.OREquipmentBoomLayout:
        return <ScreenOREquipmentBoomLayout sow={currentSOW} forPDF={false} config={config ?? undefined} />
      case Screens.PDFOutput:
        return <></>
      case Screens.StreamConnectBegin:
        return <ScreenStreamConnectBegin />
      case Screens.StreamConnectSystemSetup:
        return <ScreenStreamConnectSystemSetup sow={currentSOW} />
      case Screens.StreamConnectProjectDates:
        return <ScreenStreamConnectDates sow={currentSOW} />
      case Screens.StreamConnectTableOfContents:
        return <StreamConnectTableOfContents sow={currentSOW} forPDF={false} />
      case Screens.StreamConnectExecutiveSummary:
        return <StreamConnectExecutiveSummary sow={currentSOW} forPDF={false} currentDepartmentIndex={currentConfigOrDepartmentIndex} />
      case Screens.StreamConnectScopeOfWork: {
        return <StreamConnectScopeOfWork key={"regular"} sow={currentSOW} forPDF={false} currentSowRowIndex={0} isScreenUpgrade={false} />
      }
      case Screens.StreamConnectScopeOfWorkUpgrade: {
        return <StreamConnectScopeOfWork key={"upgrades"} sow={currentSOW} forPDF={false} currentSowRowIndex={0} isScreenUpgrade={true} />
      }
      case Screens.StreamConnectProjectRoadmap:
        return <StreamConnectProjectRoadmap sow={currentSOW} forPDF={false} currentDepartmentIndex={currentConfigOrDepartmentIndex} />
      case Screens.StreamConnectEnterperiseStrategyMap:
        return <StreamConnectEnterpriseStrategyMap sow={currentSOW} forPDF={false} currentDepartmentIndex={currentConfigOrDepartmentIndex} />
      case Screens.StreamConnectTopologyDiagram:
        return <StreamConnectTopologyDiagram sow={currentSOW} forPDF={false} currentDepartmentIndex={currentConfigOrDepartmentIndex} onReadyForPDF={() => { }} />

    }
    return <></>
  }

  return (
    <div className="App">
      <div className='scaledArea' style={{ transform: `scale(${scale})` }}>
        <DndProvider backend={HTML5Backend}>
          {!switchingConfig && (
            <>{whichScreen(loggedInUser)}</>
          )}
        </DndProvider>
        {pdfData && <ScreenPDFViewer pdfData={pdfData} sow={currentSOW} onDismiss={() => {
          setPdfData(null);
          AC.setPrintingPDF(false);
        }} />}
      </div>
      {printingPDF && pdfData == null && ( // generating the pdf outside of the scaled area
        <>
          {currentSOW.type == "OR Room Integration" ? (
            <ScreenPDFOutputOR currentSOW={currentSOW} onPDFComplete={(pdfData) => {
              setPdfData(pdfData);
            }} />
          ) : (
            <ScreenPDFOutputStreamConnect currentSOW={currentSOW} onPDFComplete={(pdfData) => {
              setPdfData(pdfData);
            }} />
          )}
        </>
      )}
    </div>
  );
}

export default App;
